<template>
	<div>
		<pui-datatable :modelName="modelName" :modelColumnDefs="modelColumnDefs" :navigableDetail="false"></pui-datatable>
	</div>
</template>

<script>
export default {
	name: 'vterminaltimesgrid',
	components: {},
	data() {
		return {
			modelName: 'terminaltimes',
			modelColumnDefs: {}
		};
	}
};
</script>
